const results = [

	{
		title: `Collegiate Chic`,
		subtitle: "Preppy, Campus Cool",
		colors: ['#FFF600', '#442978'],
		colors2: ['#FFFFFF', '#D6C328'],
		size: '1148/178',
		id: "C",
		pieces: `Sweater Vest, Chunky Loafers, Beret or Headband`,
		details: `
<p>
<b>Collegiate Chic</b> embraces more classic fashion pieces like Mary Janes, sweater vests and plaid skirts. Her look effortless blends the sophistication of academia with a modern, stylish twist.
</p>
		`,
		tips: [{
			"title": `Coordinate your look`,
			'description': `For a polished look, coordinating your outfit  — from your headband to your Mary Janes — is key! Start by selecting a color palette for the day, then build your look around it. If you opt for a yellow plaid skirt, pair it with a yellow or black sweater vest, a white shirt and black loafers for a chic ensemble.`
		}, {
			"title": `Simple silhouettes`,
			"description": `Find your chic in simplicity! Embrace classic pieces such as  peacoats, sweater sets and empire waist dresses. Ensure these wardrobe essentials are in your closet to achieve that sophisticated collegiate style.`
		}, {
			"title": `Finishing touches`,
			"description": `Elevate your ensemble with extra special details. Consider adding a beaded headband, knee-high socks paired with your Mary Janes, a mini backpack, or even a fluffy pink pen to put the finishing touches on your look. `
		}]
	},
	{
		title: `‘90s It Girl`,
		subtitle: "Laid-Back Girl Next Door",
		colors: ['#BE1E6F', '#FFF600'],
		colors2: ['#FFFFFF', '#BE1E6F'],
		size: '1148/178',
		id: "A",
		pieces: `Cargos, White Sneaker, Cozy Cardigan`,
		details: `
<p>
For our <b>‘90s It Girl</b>, casual  and cozy is her motto. Knits, cargos, cotton t-shirts, perfectly worn-in jeans and sneakers  are all staples in her closet and captures the nostalgic spirit of the 90s.
</p>
		`,
		tips: [{
			"title": `Play with proportions`,
			"description": `Experiment with proportions by mixing loose-fitting tops with  more fitted bottoms and vice versa. Try pairing  a chunky, oversized sweater with fitted leggings for a cozy look, or opt for a cargo pant matched with a cute baby tee!`
		}, {
			"title": `Layering is Key`,
			"description": `Find your go-to cardigan - it’s a Fall must have! Layer it over your ‘90s tee and plaid shirt for added warmth and a snug, stylish look during cooler weather.`
		}, {
			"title": `Essential  comfort shoes`,
			"description": `Consider timeless staples in your shoe collection, like a classic white sneaker and chunky boots. Pair the sneaker with a slip dress for a casual look, and wear the chunky boots with your cargo for a stylish edge.`
		}]

	},
	{
		title: `City Glam`,
		subtitle: "Think Sparkles, Sequins",
		colors: ['#442978', '#FFFFFF'],
		colors2: ['#FFFFFF', '#442978'],
		size: '857/162',
		id: "D",
		pieces: `Faux Fur Jacket, Slip Dress, Strappy Sandal`,
		details: `
<p>
<b>City Glam</b> is the woman out on the town, embracing her inner sparkle. She is always on the move, trying  the latest trends, and isn’t afraid to be adventurous with her fashion.
</p>
		`,
		tips: [{
			"title": `Build your look off of your statement piece`,
			"description": `We all have that one beloved item  in our closet, mine is my faux fur jacket! When putting together an outfit that makes a statement, I often reach for this piece.  Combine  your statement piece with playful  accents, such as a faux fur coat with a slip dress or  metallic shoes with a solid jumpsuit to ensure that the piece (and you!) will be the star of the show!`
		}, {
			"title": `Accessories are the cherry on top`,
			"description": `Belts, bags, hats…oh my! Nothing is ever “too much” for City Glam. Whether you’re painting the town red or having a “casual day” at the office, consider layering on jewelry or adding a fabulous hat to complete your killer look.`
		}, {
			"title": `Mix it up`,
			"description": `Pair  your elevated pieces with your everyday basics. Whether it’s dressing up your casual jeans with your favorite bag or slipping on some stylish heels, don’t be afraid to mix and match to create a unique and versatile look.`
		}]
	},
	{
		title: `Top Boss`,
		subtitle: `<span>Statement Purse, Matching Skirt or Pant Suit, Chunky Necklace or Cuff Bracelet</span>`,
		colors: ['#058743', '#DADADB'],
		colors2: ['#FFFFFF', '#058743'],
		size: '978/167',
		id: "B",
		pieces: `Statement Purse, Matching Skirt or Pant Suit, Chunky Necklace or Cuff Bracelet`,
		details: `
<p>
<b>Top Boss</b> is the head woman in charge. She leans into sleek suiting and neutral tones makes a statement with her accessories - such as an eye-catching bag or bold jewelry.
</p>
		`,
		tips: [{
			"title": `Find the right shoe for your power suit`,
			"description": `Boots, ballet flats, pumps…there are so many options to choose from! With wide-leg pants, opt for  pointed shoes for a sleek look, while an a-line skirt is fantastic with a knee-high boots. For a straight, fitted trouser, elevate your ensemble with a stylish pump or a timeless ballet  flat.`
		}, {
			"title": `Monochromatic Look`,
			"description": `To be a Top Boss, create a powerful presence by keeping it monochromatic. Start with your favorite neutral shade  — whether it’s  beige, gray, brown, black or cream  and build your sleek look from there. Infuse depth by integrating various shades within the same color family, creating a striking and cohesive look. `
		}, {
			"title": `Power Up with Statement Accessories`,
			"description": `Pick one bold accessory to take center stage of your sleek look — a statement necklace or earring, a chunky cuff, or an oversized purse, let this standout piece be the focal point that adds an extra dose of power to your ensemble. `
		}]
	},
	{
		title: `Maximalista`,
		subtitle: "<span>Bold Patterns, Layers,</span> <span>Lots of Color</span>",
		colors: ['#DAB47B', '#D71F26'],
		colors2: ['#FFFFFF', '#D71F26'],
		size: '915/245',
		id: "E",
		pieces: `Waist Belt, Patterned Blouse, Colorful Pump`,
		details: `
<p>
The <b>Maximalista</b> loves to experiment with patterns, textures and colors -and inspires those around her with her bold style.
</p>
		`,
		tips: [{
			"title": `Have fun with patterns`,
			"description": `Fashion is all about having fun! Find your favorite patterns and add them to your wardrobe — whether it’s  a blouse, a pair of pants or a scarf -  choose whatever brings  you joy. Dare to be  adventurous and let your style shine with patterns that reflect your unique personality!`
		}, {
			"title": `The power of layering`,
			"description": `The Maximalista knows there’s no such thing as “too much”! Elevate your  look by layering different patterns, colors and textures. Remember, your favorite patterns will pop even more  when paired with solid pieces, adding dimension and personality to your outfit. `
		}, {
			"title": `Belts are your best friend`,
			"description": `Belts are not just functional, they have the magical ability to pull your entire ensemble together, creating a harmonious and polished look that’s ready for any occasion.`
		}]
	},
];

export default results;
