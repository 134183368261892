import { forwardRef, useEffect } from "react";
import Wrapper from "../molecules/Wrapper";
import { getHeights } from "../../utils/core";
import AspectImage from "../atoms/aspect-image";

const Header = forwardRef( (props, ref) => {
	const attributes = {
		...props,
		children: null,
		className: null
	}

	useEffect(() => {
		getHeights.headerHeight();
	}, []);

	return <>
		<header ref={ ref } { ...attributes } className={`tw-fixed tw-top-0 tw-w-full tw-z-10 tw-bg-[#9AE4D3] tw-text-black tw-select-none tw-py-18px md:tw-py-25px md:tw-py-0 tw-flex tw-w-full tw-items-center tw-justify-center tw-px-40px tw-text-center ${ props.className ? " || " + props.className : '' }`}>
			<Wrapper>
				<span className="tw-font-primary tw-font-primary-bold tw-text tw-text-gheader tw-uppercase tw-tracking-wide tw-inline-flex tw-items-center">
					<AspectImage src="/images/logo_stitch-fix_00.svg" aspect="214/25" alt="Stitch Fix" className="tw-ml-8p tw-mr-8p tw-min-w-100px" />
				</span>
			</Wrapper>
		</header>
	</>
});


export default Header;
