import { useEffect, useLayoutEffect, useState } from "react";
import { useAppContext } from "../AppContext";
import results from "../utils/results";
import questions from "../utils/questions";
import AspectImage from "../components/atoms/aspect-image";
import ButtonDefault, { ButtonPrimary } from "../components/atoms/Button";
import Wrapper, { WrapperInner } from "../components/molecules/Wrapper";
import { QuestionNumber } from "./Question";
import { ParagraphBlock, ScreenSubHeader, SectionSubheader } from "../components/atoms/headers";

import {
	FacebookShareButton,
	PinterestShareButton,
	TwitterShareButton
  } from "react-share";


const copyText = string => {
	if (!string) return;
	if (navigator.clipboard) {
		navigator.clipboard.writeText(string);
	}else {
		if (document.execCommand) {
			document.execCommand(string);
		}
	}
	return Promise.resolve(string);
}

const stripHTMLTags = (str) => str.replace(/<[^>]*>/g, '').replace(/\s+/g, ' ');



const Tip = (props) => {
	return <>
		<div className={` tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-space-x-30px tw-w-full ${ props.className ? ' || ' + props.className : '' }`}>
			<div className="tw-order-2 md:tw-order-1 tw-mt-15px tw-mb-10px tw-hidden md:tw-flex tw-flex-row tw-flex-shrink-0 md:tw-mr-20px">
				<span className="tw-flex tw-items-center tw-flex-shrink-0 tw-w-50px tw-text-[#98E2D8]">
					<QuestionNumber number={ props.index }  />
				</span>
			</div>
			<div className="tw-order-1 md:tw-order-2 tw-flex-shrink-0 tw-w-full md:tw-w-percent" style={{ '--max-width': 560 }}>
				<AspectImage src={`/images/${ props.image }-fs8.png`} aspect={ 559/375 } className="tw-w-full tw-rounded-20px" alt={`Tip #${Number(props.index) + 1}: ${stripHTMLTags(props.title)}`} />
			</div>
			<div className="tw-order-3 md:tw-order-3 tw-flex md:tw-flex-grow tw-flex-col md:tw-justify-stretch  tw-w-full">
				<div className="tw-w-full md:tw-min-h-90px tw-pt-10px md:tw-pt-15px tw-pb-15px  md:tw-pb-30px tw-flex tw-flex-row tw-items-center  md:tw-block">
					<span className="tw-flex  md:tw-hidden tw-select-none tw-items-center tw-flex-shrink-0 tw-w-30px tw-text-[.75em] tw-text-[#98E2D8] tw-mr-10px">
						<QuestionNumber number={ props.index }  />
					</span>
					<SectionSubheader dangerouslySetInnerHTML={{__html: props.title }} />
				</div>
				<div className="tw-w-full">
					<ParagraphBlock tag="div">
						<div className="md:tw-text-[.8em] tw-space-y-10px md:tw-space-y-15px" dangerouslySetInnerHTML={{__html: props.description }} />
					</ParagraphBlock>
				</div>
			</div>
		</div>
	</>
}

const ShareIcons = ({ obj, url = 'https://stitchfix.maincharacterclosets.com' }) => {
	const appContext = useAppContext();
	const handleDownload = (e, url) => {
		appContext.ReactGA.event({
			category: "Social Share",
			action: "Click",
			label: "Download"
		});

		if (e) e.preventDefault();
		var link = document.createElement("a");
		link.setAttribute('download', 'Stitch-Fix-Results.png');
		link.href = url.replaceAll("/#quiz", "");
		document.body.appendChild(link);
		link.click();
		link.remove();
	}


	const socialShare = (type) => {
		appContext.ReactGA.event({
			category: "Social Share",
			action: "Click",
			label: type
		});
	}

	return obj?.title && <>
		<span className="tw-flex -tw-my-5px -tw-mx-10px md:-tw-mx-15px">
			<span className="tw-inline-flex tw-flex-row tw-flex-wrap">
				<span className="tw-py-5px tw-px-10px md:tw-px-15px">
					<ButtonDefault url={ url } title="Download Shareable" onClick={ e => handleDownload(e, window.location.href + "/images/" + obj.id + "_download.png") }>
						<AspectImage src="/images/icon-download.svg" className="tw-w-20px md:tw-w-40px" />
					</ButtonDefault>
				</span>
				<span className="tw-py-5px tw-px-10px md:tw-px-15px">
					<FacebookShareButton onClick={ e => { socialShare('Facebook') } } url={ url } quote={`I'm a ${stripHTMLTags(obj.title)}`}>
						<AspectImage src="/images/icon-facebook.svg" className="tw-w-20px md:tw-w-40px" />
					</FacebookShareButton>
				</span>
				{/* <span className="tw-py-5px tw-px-10px md:tw-px-15px">
					<TwitterShareButton url={ url } title={`I'm a ${stripHTMLTags(obj.title)}`}>
						<AspectImage src="/images/icon-instagram.svg" className="tw-w-20px md:tw-w-40px" />
					</TwitterShareButton>
				</span> */}
				{/* <span className="tw-py-5px tw-px-10px md:tw-px-15px">
					<TwitterShareButton url={ url } title={`I'm a ${stripHTMLTags(obj.title)}`}>
						<AspectImage src="/images/icon-twitter.svg" className="tw-w-20px md:tw-w-40px" />
					</TwitterShareButton>
				</span> */}
				<span className="tw-py-5px tw-px-10px md:tw-px-15px">
					<PinterestShareButton onClick={ e => { socialShare('Pinterest') } } url={ url } description={`I'm a ${stripHTMLTags(obj.title)}`} media={window.location.href.replaceAll("/#quiz","") + "/images/" + obj.id + "_download.png"}>
						<AspectImage src="/images/icon-pinterest.svg" className="tw-w-20px md:tw-w-40px" />
					</PinterestShareButton>
				</span>
				{/* <span className="tw-py-5px tw-px-10px md:tw-px-15px">
					<TwitterShareButton url={ url } title={`I'm a ${stripHTMLTags(obj.title)}`}>
						<AspectImage src="/images/icon-tiktok.svg" className="tw-w-20px md:tw-w-40px" />
					</TwitterShareButton>
				</span> */}
			</span>
		</span>
	</>
}


const ResultsScreen = (props) => {
	const appContext = useAppContext();
	const [resultObj, setResultObj] = useState(null);
	const [isReady, setIsReady] = useState(false);

	const attributes = {
		...props,
		children: null,
		className: null,
	};

	const combinedResults = (obj) => {
		return obj.reduce((acc, result) => {
			if (acc[result.value]) {
				acc[result.value] += result.points;
			} else {
				acc[result.value] = result.points;
			}
			return acc;
		}, {});
	};

	const sortedObj = (obj) => {
		return Object.fromEntries(Object.entries(obj).sort((a, b) => b[1] - a[1]));
	};

	const getKeyByIndex = (obj, index = 0) => Object.keys(obj)[index];

	const getResultByKey = (id) => results.find((result) => result.id === id);

	useEffect(() => {
		if (resultObj) {
			// console.clear();
			// console.log(resultObj)
			// console.log("Full Result Oject (appContext.score)", appContext.score);
			// console.log("Combined Totals (combinedResults(appContext.score))", combinedResults(appContext.score));
			// console.log("Sorted Results (orderesortedObjdObj(combinedResults(appContext.score)))", sortedObj(combinedResults(appContext.score)));
			// console.log("Highest Value", getKeyByIndex(sortedObj(combinedResults(appContext.score))) );
			// console.log("2nd Place", getKeyByIndex(sortedObj(combinedResults(appContext.score)),1));
			// console.log("THE RESULT", getResultByKey(getKeyByIndex(sortedObj(combinedResults(appContext.score)),0)));
			setResultObj(getResultByKey(getKeyByIndex(sortedObj(combinedResults(appContext.score)))));
		}
	}, [resultObj]);

	useEffect(() => {
		if (appContext.currentQuestion >= questions.length) {
			setResultObj(sortedObj(combinedResults(results)));

			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});

			setTimeout(() => {
				setIsReady(true);
			}, 500);
		}
	}, [appContext.currentQuestion, appContext.score]);

	const handleCopy = (e) => {

		appContext.ReactGA.event({
			category: "Social Share",
			action: "Click",
			label: "Copy Text"
		});

		e.preventDefault();

		copyText( `I'm a ${resultObj.title}\n\n${ stripHTMLTags( resultObj?.details )}\n\nTake the quiz: https://stitchfix.maincharacterclosets.com` ).then( () => {
			const target = e.target

			target.classList.add("tw-bg-[#9AE4D3]");
			target.querySelector("._text").innerHTML = "Copied!"
			target.querySelector("._image").classList.add("!tw-hidden")
		});
	}

	const goToStitchFix = () => {
		appContext.ReactGA.event({
			category: "Go To Stitch Fix",
			action: "Click",
			label: "Partner with a Stitch Fix"
		});
	}

	useEffect(() => {
		if (appContext.currentQuestion >= questions.length && resultObj && resultObj.title) appContext.ReactGA.send({ hitType: "pageview", page: `/Results`, title: `Results - ${resultObj.title}` });
	}, [appContext.currentQuestion, questions, resultObj]);

	return (
		appContext.currentQuestion >= questions.length && resultObj && resultObj.colors2 && <>
			<div className="tw-bg-white tw-min-h-header-height tw-w-full">
				<div {...attributes} className={`${isReady === true ? 'tw-opacity-100 tw-blur-none' : 'tw-opacity-0 tw-blur-lg' } tw-delay-50 tw-duration-500 || tw-w-full tw-pt-header-height tw-bg-[var(--bg)] tw-text-[var(--text)] ${props.className ? props.className + " || " : ""}`} style={{ '--bg': resultObj.colors2 && resultObj?.colors2[0], '--text': resultObj.colors2 && resultObj?.colors2[1] }}>
					<ScreenSubHeader tag="span" className="tw-block tw-uppercase tw-text-center tw-pt-40px md:tw-pt-50px tw-mt-5px">
						Your main character closet is {` `}
						<span className="tw-hidden">{resultObj && resultObj.title}</span>
					</ScreenSubHeader>
					<div className="tw-bg-[var(--bg)] tw-text-[var(--text)] tw-px-40px md:tw-px-60px tw-space-y-15px tw-pb-30px tw-flex tw-flex-col tw-justify-center">
						<div className="tw-w-full tw-mx-auto md:tw-w-percent tw-flex tw-justify-center tw-pt-20px md:tw-pt-30px tw-pb-10px md:tw-pb-20px" style={{ '--max-width': resultObj?.size.split('/')[0] }}>
							<AspectImage src={`/images/${ resultObj?.id }_results.svg`} contain aspect={ resultObj?.size } className="tw-w-full" />
						</div>
						<div className="md:tw-w-percent tw-mx-auto" style={{ '--max-width': 725 }}>
							<AspectImage src={`/images/${ resultObj?.id }-fs8.png`} alt={ resultObj.title } aspect="720/455" cover className="tw-w-full tw-border-3px tw-border-current" />
						</div>
					</div>
					<div className="tw-divide-y-1px md:tw-divide-y-0px tw-divide-black tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-border-block md:tw-divide-x-3px  tw-border-black tw-divide-black tw-border-y-1px md:tw-border-y-3px">
						<div className="md:tw-w-1/2 md:tw-basis-1/2 tw-flex-grow tw-flex-shrink-0">
							<ButtonPrimary onClick={ goToStitchFix } href="https://www.stitchfix.com/" target="_blank" title="Get Your Personalized Fix | Stitch Fix" className="tw-min-h-75px tw-w-full tw-text-center">
								Partner with a Stitch Fix <span className="tw-inline-block">Expert Stylist</span>
							</ButtonPrimary>
						</div>
						<div className="md:tw-w-1/2 md:tw-basis-1/2 tw-flex-grow tw-flex-shrink-0 tw-bg-white tw-items-center tw-justify-center">
							<div  className="tw-min-h-75px tw-w-full tw-flex tw-items-center tw-justify-center">
								<span className="tw-font-primary tw-font-primary-medium tw-leading-wide tw-text-button tw-uppercase tw-leading-none">
									<span className="tw-block -tw-m-2px md:-tw-m-5px">
										<span className="tw-flex tw-flex-wrap tw-items-center tw-flex-row tw-w-full tw-space-x-20px">
											<span className="tw-inline-flex tw-items-center tw-leading-none tw-text-black tw-p-2px  md:tw-p-5px">
												Share
											</span>
											<span className="tw-inline-flex tw-space-x-15px || tw-transition-all tw-duration-300 hover:tw-duration-500 tw-p-2px md:tw-p-5px">
												<ShareIcons  obj={ resultObj }  />
											</span>
										</span>
									</span>
								</span>
							</div>
						</div>
					</div>
					<div className="tw-w-full tw-bg-white tw-text-black tw-pt-40px md:tw-pt-70px tw-pb-50px md:tw-pb-75px tw-space-y-30px md:tw-space-y-70px">
						<Wrapper>
							<WrapperInner>
								<ParagraphBlock tag="div" className="tw-space-y-20px md:tw-space-y-30px" dangerouslySetInnerHTML={{__html: resultObj?.details }}/>
								<div className="-tw-m-5px">
									<div className="tw-pt-20px md:tw-pt-70px tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-flex-wrap tw-w-full tw-justify-center">
										<ParagraphBlock tag="p" className="tw-flex-shrink-0 tw-m-5">
											<span className="tw-font-primary-medium">
												Let your stylist know you are
												{ resultObj?.title === 'City Glam' || resultObj?.title === 'Top Boss' || resultObj?.title === 'Collegiate Chic' ? <>
													&nbsp;
												</> : ' a ' }
												<span className="tw-inline-block">{ resultObj?.title }!</span>
											</span>
										</ParagraphBlock>
										<ButtonDefault onClick={  e => handleCopy(e) } className="tw-bg-[#E4E0E0] hover:tw-bg-[#9AE4D3] tw-inline-flex md:tw-min-w-200px tw-items-center tw-px-20px tw-flex-shrink-0 md:tw-m-5px tw-min-h-50px md:tw-min-h-70px tw-group">
											<ParagraphBlock tag="span" removeDefaults className="tw-leading-none tw-inline-flex tw-items-center tw-w-full">
												<span className="tw-font-primary-medium tw-whitespace-nowrap md:tw-pr-10p _text tw-flex-grow tw-text-center">
													Copy Now
												</span>
												<AspectImage src="/images/icon-copy.svg" contain aspect="31/41" className="_image tw-flex-shrink-0 tw-transition-all tw-duration-300 hover:tw-duration-500 tw-w-[15%]" />
											</ParagraphBlock>
										</ButtonDefault>
									</div>
								</div>
							</WrapperInner>
						</Wrapper>
					</div>
				</div>
				<div className={`${isReady === true ? 'tw-opacity-100 tw-blur-none' : 'tw-opacity-0 tw-blur-lg' } tw-w-full tw-bg-white tw-text-black`}>
					<div className="tw-pb-40px md:tw-pb-0px">
						<div className="tw-border-y-1px md:tw-border-y-3px tw-border-black tw-sticky tw-top-header-height tw-bg-white tw-z-5">
							<Wrapper>
								<ScreenSubHeader tag="h4" className="tw-uppercase tw-text-center tw-py-15px tw-w-full">
									Mona May's Styling Tips
								</ScreenSubHeader>
							</Wrapper>
						</div>
						<Wrapper className="tw-text-center">
							<ParagraphBlock>
								<p className="md:tw-text-[.8em] tw-tracking-widest tw-pt-40px md:tw-pt-50px md:tw-pb-10px">
									<b className="tw-inline-block tw-font-primary-bold">
										Three Key Pieces:
									</b>
									{` `}
									{ resultObj && resultObj.pieces }
								</p>
							</ParagraphBlock>
						</Wrapper>
						<div className="tw-divide-y-1px tw-divide-black md:tw-divide-y-0px tw-space-y-40px md:tw-py-40px">
							{ resultObj?.tips && resultObj.tips.map((tip, index) => {
								return <div className="tw-w-full tw-pt-40px md:tw-pt-0px" key={ index }>
									<Wrapper>
										<WrapperInner>
											<Tip index={ index } title={ tip.title } description={ tip.description } image={`${  resultObj.id }_${ index + 1 }`} />
										</WrapperInner>
									</Wrapper>
								</div>
							}) }
						</div>
					</div>
					<div className="tw-w-full tw-flex tw-flex-row tw-border-block tw-divide-x-1px md:tw-divide-x-3px  tw-border-black tw-divide-black">
						<div className="tw-divide-y-1px md:tw-divide-y-0px tw-divide-black tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-border-block tw-divide-x-1px md:tw-divide-x-3px  tw-border-black tw-divide-black tw-border-y-1px md:tw-border-y-3px">
							<div className="md:tw-w-1/2 md:tw-basis-1/2 tw-flex-grow tw-flex-shrink-0">
								<ButtonPrimary onClick={ goToStitchFix }href="https://www.stitchfix.com/" target="_blank" title="Get Your Personalized Fix | Stitch Fix" className="tw-min-h-75px tw-w-full tw-text-center">
									Partner with a Stitch Fix <span className="tw-inline-block">Expert Stylist</span>
								</ButtonPrimary>
							</div>
							<div className="md:tw-w-1/2 md:tw-basis-1/2 tw-flex-grow tw-flex-shrink-0 tw-bg-white tw-items-center tw-justify-center">
								<div  className="tw-min-h-75px tw-w-full tw-flex tw-items-center tw-justify-center">
									<span className="tw-font-primary tw-font-primary-medium tw-leading-wide tw-text-button tw-uppercase tw-leading-none">
										<span className="tw-block -tw-m-2px md:-tw-m-5px">
											<span className="tw-flex tw-flex-wrap tw-items-center tw-flex-row tw-w-full tw-space-x-20px">
												<span className="tw-inline-flex tw-items-center tw-leading-none tw-text-black tw-p-2px  md:tw-p-5px">
													Share
												</span>
												<span className="tw-inline-flex tw-space-x-15px || tw-transition-all tw-duration-300 hover:tw-duration-500 tw-p-2px md:tw-p-5px">
													<ShareIcons  obj={ resultObj }  />
												</span>
											</span>
										</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResultsScreen;
