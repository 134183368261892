import { useEffect, useLayoutEffect, useRef } from "react";
import { AppProvider } from "./AppContext";
import Footer from "./components/organisms/Footer";
import Header from "./components/organisms/Header";
import HomeScreen from "./screens/Home";
import QuestionScreen from "./screens/Question";
import ResultsScreen from "./screens/Results";
import questions from "./utils/questions";
import { getHeights } from "./utils/core";

const App = (props) => {
	const header = useRef();

	useEffect( () => {
		if (header && header.current) getHeights.all(header.current);
	}, [header]);

	return <>
		<AppProvider>
			<Header ref={ header }/>
			<main className="tw-flex tw-flex-col tw-justify-stretch tw-items-start">
				<HomeScreen className="tw-w-full" />
				{ questions.map( (question, index) => {
					return <QuestionScreen key={ index } question={ question } index={ index } />
					// return index === appContext.currentQuestion ? <>
					// 	<QuestionScreen key={ index } question={ question } index={ index } />
					// </> : null
				}) }
				<ResultsScreen />
			</main>
			{/* <Footer /> */}
		</AppProvider>
	</>
};

export default App;
