const AspectImage = props  => {
	const attributes = {
		...props,
		src: null,
		alt: null,
		aspect: null,
		className: null,
		contain: null,
		cover: null,
		video: null,
		imageClasses: null
	}

	return <>
		<span { ...attributes } className={`tw-select-none tw-inline-flex tw-items-center tw-justify-center tw-relative tw-overflow-hidden tw-aspect-[var(--aspect)] ${ props.className ? '\n || ' + props.className : '' }`} style={{ '--aspect': props.aspect || props.ratio || '1/1'}}>
			{ props.video ? <>
				<iframe src={`https://www.youtube.com/embed/oHg5SJYRHA0?si=${ props.video || 'FanAUNWGzOMINLSb' }`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="tw-block tw-w-full tw-h-full tw-object-cover tw-object-center tw-absolute -tw-top-2px -tw-right-2px -tw-bottom-2px -tw-left-2px"></iframe>
			</> : <>
				<img src={ props.src || 'images/placeholder_2x2.png' } alt={ props.alt } className={`tw-block tw-w-full tw-h-full  ${ props.imageClasses ? ' || ' +  props.imageClasses + ' || ' : '' } ${ props.contain ? 'tw-object-contain' : 'tw-object-cover' } ${ !props.contain || props.cover ? 'tw-object-cover' : '' } tw-object-center`} />
			</>}

		</span>
	</>
}

export default AspectImage;
