export const debounce = (fn, delay = 500) => {
	let timer = null;
	return function () {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};

export const getHeights = {
	rootStyles: document.documentElement.style,
	windowHeight: () => {
		const height = window.innerHeight;
		document.documentElement.style.setProperty("--vh", `${height * 0.01}px`);
		return height;
	},
	headerHeight: (header) => {
		if (header && header.offsetHeight && header.offsetHeight > 10) {
			getHeights.rootStyles.setProperty("--headerHeight", `${header.offsetHeight}px`);
		}
	},
	resize: (header) => {
		if (debounce && window.matchMedia('(min-width: 47.5rem)').matches ) {
			window.addEventListener(
				"resize",
				debounce(() => {
					getHeights.windowHeight();
					getHeights.headerHeight(header);
				}, 150)
			);
		}
	},
	all: (el) => {
		getHeights.resize(el);
		getHeights.windowHeight();
		getHeights.headerHeight(el);
	},
};
