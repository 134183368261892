export const Gradient = `tw-bg-gradient-to-b tw-from-primary-dark tw-via-primary tw-to-primary-light`;
export const TextGradient = `${ Gradient } tw-text-transparent tw-bg-clip-text`;

const BaseHeader = props => {
	const Tag = props.tag || 'h2';

	const attributes = {
		...props,
		children: null,
		className: null,
		tag: null,
		isgradient: null
	}

	return <>
		<Tag { ...attributes } className={`${ props.isgradient ? '\n || ' +  TextGradient : '' } ${ props.className ? '\n || ' + props.className : '' }`}>
			{ props.children }
		</Tag>
	</>
}

export const ScreenHeader = props => {
	const attributes = {
		...props
	}

	return <>
		<BaseHeader { ...attributes } className={` tw-font-primary tw-font-primary-bold tw-text tw-text-header tw-uppercase tw-tracking-tight ${ props.className ? '\n || ' + props.className : '' }`} />
	</>
}

export const ScreenSubHeader = props => {
	const attributes = {
		...props
	}
	return <>
		<BaseHeader  { ...attributes } className={` tw-font-primary tw-font-primary-medium tw-text tw-text-subheader tw-uppercase tw-tracking-wide tw-leading-none ${ props.className ? '\n || ' + props.className : '' }`} />
	</>
}

export const SectionHeader = props => {
	const attributes = {
		...props
	}

	return <>
		<BaseHeader  { ...attributes } className={` tw-font-primary tw-font-primary-medium tw-text tw-text-dynamic tw-tracking-wide tw-leading-none ${ props.className ? '\n || ' + props.className : '' }`} style={{'--max-font-size': 65, '--min-font-size': 14 }} />
	</>
}

export const SectionSubheader = props => {
	const attributes = {
		...props
	}

	return <>
		<BaseHeader  { ...attributes } className={`tw-font-secondary tw-font-secondary-bold tw-uppercase tw-tracking-tight tw-leading-none tw-text-3 ${ props.className ? '\n || ' + props.className : '' }`} />
	</>
}


export const ArticleHeader = props => {
	const attributes = {
		...props
	}

	return <>
		<BaseHeader  { ...attributes } className={`tw-font-secondary tw-font-secondary-bold tw-tracking-normal tw-leading-none tw-text-3 ${ props.className ? '\n || ' + props.className : '' }`} />
	</>
}

export const ArticleSubheader = props => {
	const attributes = {
		...props
	}

	return <>
		<BaseHeader  { ...attributes } className={`tw-font-secondary tw-font-secondary-bold tw-uppercase tw-tracking-tight tw-leading-none tw-text-4 ${ props.className ? '\n || ' + props.className : '' }`} />
	</>
}

export const ParagraphBlock = props => {
	const Tag = props.tag || 'div';
	const attributes = {
		...props,
		className: null,
		isgradient: null,
		tag: null,
		default: null
	}

	return <>
		<Tag  { ...attributes } className={`tw-font-primary tw-font-primary-normal tw-tracking-tight tw-text-paragraph ${ props.removeDefaults ? '' : `tw-leading-[1.4] md:tw-leading-[1.2]`}  ${ props.isgradient ? '\n || ' +  TextGradient : '' } ${ props.className ? '\n || ' + props.className : '' }`}>
			{ props.children }
		</Tag>
	</>
}



export default BaseHeader;
