import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppContext } from "../AppContext";
import { ButtonPrimary } from "../components/atoms/Button";
import { Block, Span } from "../components/atoms/Span";
import AspectImage from "../components/atoms/aspect-image";
import Wrapper, { WrapperInner } from "../components/molecules/Wrapper";
import { ParagraphBlock, ScreenHeader, ScreenSubHeader, SectionHeader } from "../components/atoms/headers";
import results from "../utils/results";

const HomeScreen = props => {
	const resultSetsRefs = useRef(null);
	const appContext = useAppContext();
	const [isActive, setIsActive] = useState(true);

	const attributes = {
		...props,
		children: null,
		className: null
	}

	const handleOnClick = e => {
		e.preventDefault();
		appContext.setCurrentQuestion(0);
		setIsActive( false );
		// window.location.replace(window.location.origin);
		window.scrollTo(0, 0);
	}

	useEffect(() => {
		if (appContext.currentQuestion === null) setIsActive(true);
	}, [appContext.currentQuestion]);


	useEffect(() => {
		if (resultSetsRefs && resultSetsRefs.current) {
			/*
			const observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					// console.log(entry, entry.intersectionRatio, entry.boundingClientRect.height)
					if (entry.intersectionRatio > 0) {
						entry.target.classList.add('active');
					} else {
						entry.target.classList.remove('active');
					}
				});
			}
			, {
				rootMargin: `50% 0px -50% 0px`
			});


			resultSetsRefs.current.querySelectorAll(`.resultSetsRefsItem`).forEach( (item, index) => {
				observer.observe(item);
			});
			*/
		}
	}, [resultSetsRefs]);

	useLayoutEffect(() => {
		appContext.ReactGA.send({ hitType: "pageview", page: "/#Home", title: "Home" });
	}, []);


	return <>
		<div { ...attributes } className={`${ props.className ? props.className + ' || '  : '' } ${ isActive ? 'tw-flex' : 'tw-hidden' } tw-min-h-header-offset tw-bg-white tw-text-black tw-flex-col tw-items-stretch tw-w-full || tw-text-center`} style={{ '--offset':  window.matchMedia('(min-width: 47.5rem)').matches ? '4.875rem' : '4.75rem'  }}>
			<div className="tw-pt-header-height md:tw-pt-0 tw-min-h-vh tw-flex tw-flex-col tw-w-full">
				<Wrapper noPaddingMobile className="tw-bg-white tw-text-[#25282D] tw-flex-grow tw-flex">
					<div className="tw-w-full tw-space-y-15px md:tw-space-y-20px tw-leading-none tw-flex tw-flex-col md:tw-flex-row md:tw-min-h-800px">
						<div className="tw-pt-15px md:tw-pt-0px rtw-flex-shrink-0 tw-py-10px md:tw-py-0px tw-w-full md:tw-w-percent tw-overflow-x-hidden md:tw-overflow-y-hidden md:tw-max-h-full tw-relative" style={{ '--max-width': 520 }}>
							<div className="tw-w-full md:tw-h-full md:tw-absolute tw-object-cover tw-flex tw-justify-center">
								<div class="marquee-container-sm md:marquee-container tw-max-w-full md:tw-max-w-100vw md:tw-h-full tw-flex tw-flex-col">
									<div class="marquee tw-flex tw-flex-row md:tw-flex-col tw-overflow-clip tw-select-none tw-w-full md:tw-h-full">
										<div class="marquee__group-sm md:marquee__group tw-flex-shrink-0 tw-flex tw-flex-row md:tw-flex-col tw-items-center tw-justify-around tw-min-w-full md:tw-min-h-full">
											{ [...Array(3)].map( (item, index) => {
												return <div key={ index } className="tw-w-[75vw] md:tw-w-full tw-flex-shrink-0">
													<AspectImage src={`/images/Mona_${ index + 1 }.png`} cover className="tw-w-full tw-rounded-20px tw-overflow-hidden tw-border-7px tw-border-[#98E2D8]" aspect={ 632/422 } />
												</div>
											}) }
										</div>
										<div class="marquee__group-sm md:marquee__group tw-flex-shrink-0 tw-flex tw-flex-row md:tw-flex-col tw-items-center tw-justify-around tw-min-w-full md:tw-min-h-full">
											{ [...Array(3)].map( (item, index) => {
												return <div key={ index } className="tw-w-[75vw] md:tw-w-full tw-flex-shrink-0">
													<AspectImage src={`/images/Mona_${ index + 1 }.png`} cover className="tw-w-full tw-rounded-20px tw-overflow-hidden tw-border-7px tw-border-[#98E2D8]" aspect={ 632/422 } />
												</div>
											}) }
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tw-flex-grow tw-pb-30px md:tw-pb-50px md:tw-py-100px tw-flex-grow tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-15px md:tw-px-20px">


							<h2 className="tw-space-y-6px md:tw-space-y-10px tw-flex tw-flex-col">
								<ScreenHeader tag="span" className="tw-font-primary tw-font-primary-bold tw-text tw-text-subheader tw-uppercase tw-tracking-wide -tw-mb-8px md:tw-mb-0px">
									<span className="tw-block">
										Stitch Fix partners with
									</span>
									<span className="tw-block  tw-leading-none">
										<span className="tw-inline-block">
											Costume Designer
										</span>&nbsp;
										<span className="tw-inline-block">
											Mona May
										</span>
									</span>
								</ScreenHeader>
							</h2>

							<ParagraphBlock tag="span" className="tw-block tw-pt-30px tw-w-full">
								<span className="tw-block tw-text-[.8em] tw-uppercase tw-tracking-wide tw-font-primary-normal">
									<span>
										Clueless,{` `}
									</span>
									<span>
										Never Been Kissed,{` `}
									</span>
									<span className="tw-block">
										Romy &amp; Michelle's High School Reunion
									</span>
								</span>
							</ParagraphBlock>

							<h1 className="tw-space-y-10px md:tw-space-y-6px tw-flex tw-flex-col tw-pt-20px md:tw-pt-30px">

								<ScreenHeader tag="span" className="tw-text-header tw-font-primary-bold tw-uppercase tw-tracking-tight">
									<span className="tw-text-[.8em] tw-block tw-leading-none md:tw-leading-[.85] tw-font-primary-bold">
										<Span className="md:tw-block">
											Channel Your Main
										</Span>{` `}
										<Span className="md:tw-block">
											Character Energy
										</Span>
									</span>
								</ScreenHeader>
							</h1>
							<ParagraphBlock tag="span" className="tw-block  tw-pt-20px md:tw-pt-30px tw-w-full">
								<span className="tw-block tw-text-[.8em] tw-uppercase tw-tracking-wide tw-font-primary-normal">
									Inspired by the Wardrobes of TV <span className="md:tw-block">and Film's Most Recognizable <Span>Leading Ladies</Span></span>
								</span>
							</ParagraphBlock>

							{/*
							<ParagraphBlock tag="span" className="tw-block tw-pt-30px md:tw-pt-60px tw-max-w-600px tw-mx-auto">
								<span className="tw-text-[.75em] tw-tracking-wide tw-font-primary-normal tw-upper -tw-mt-5px md:tw-mt-0px tw-block">
									​Start building your curated closest with pieces inspired by the wardrobes of tv and film's most recognizable <Span>leading ladies.</Span>
								</span>
							</ParagraphBlock>
							 */}

						</div>
					</div>
				</Wrapper>
			</div>
			<div className="tw-bg-white tw-text-black">

				<div className="tw-w-full tw-flex-shrink-0 tw-sticky tw-top-header-height tw-z-5 tw-border-y-1px md:tw-border-y-3px tw-border-black">
					<ButtonPrimary className="tw-min-w-250px tw-px-20px tw-py-10px tw-w-full tw-min-h-75px" onClick={ handleOnClick }>
						<span>Discover your Main Character</span> <span className="tw-inline-block">Closet Here</span>
					</ButtonPrimary>
				</div>
				{/*
				<div className="tw-leading-none tw-py-20px md:tw-py-40px tw-px-20px md:tw-px-40px tw-bg-white tw-border-b-1px md:tw-border-b-3px tw-border-[#29605E] tw-z-5">
					<ScreenSubHeader>
						Build your main character closet
					</ScreenSubHeader>
				</div>
				*/}
				<div className="tw-border-b-1px md:tw-border-b-3px tw-border-black tw-divide-y-1px md:tw-divide-y-3px tw-divide-black tw-flex tw-flex-col tw-justify-stretch tw-overflow-clip tw-relative" ref={ resultSetsRefs }>
					{ results.map( (item, index) => {
						return <div  key={ index } className="tw-w-full">
							<div className="resultSetsRefsItem o-layered tw-w-full tw-group tw-w-full">
								<div id={`item_${ index }`} className="[.resultSetsRefsItem.active_>_&]:!tw-opacity-0 || o-layered__layer tw-flex tw-items-center tw-justify-center tw-z-4 tw-relative tw-px-30px  md:tw-px-60px tw-px-10px md:tw-px-20px tw-cursor-default tw-bg-white tw-w-full tw-opacity-100 group-hover:tw-opacity-0 tw-transition-all tw-duration-300 hover:tw-duration-500">
									<div className="tw-w-full tw-max-w-850px tw-mx-auto tw-py-5px tw-flex tw-items-center tw-justify-center tw-grayscale tw-brightness-0">
										<AspectImage className="tw-w-full md:tw-w-[80%]" src={`/images/${ item.id }_banner.svg`} aspect="850/155" />
									</div>
								</div>
								<div className="o-layered__layer tw-z-2 tw-relative tw-bg-[var(--bg)] tw-py-5px tw-px-30px md:tw-px-60px  tw-text-[var(--text)] tw-w-full tw-flex tw-items-center tw-justify-center" style={{ '--bg': item.colors[0], '--text': item.colors[1]  }}>
									<SectionHeader tag="h5">
										<span className="tw-text-inherit tw-uppercase tw-text-[2em] md:tw-text-[.7em] tw-block tw-text-center tw-w-full" dangerouslySetInnerHTML={{__html: `<span class="tw-inline-block tw-px-30px">“${item.subtitle}”</span><span>` }}  />
									</SectionHeader>
								</div>
							</div>
						</div>
					}) }
				</div>
			</div>
			<div className="tw-bg-white tw-text-black">
				<div className="tw-py-20px md:tw-py-40px tw-px-20px md:tw-px-30px md:tw-px-60px tw-sticky tw-top-header-height tw-z-2 tw-bg-white tw-border-b-1px md:tw-border-b-3px tw-border-black">
					<ButtonPrimary className="tw-min-w-250px tw-px-20px tw-py-10px tw-w-full  md:tw-w-auto tw-px-30px tw-min-h-75px" onClick={ handleOnClick }>
						<span>Discover your Main Character</span> <span className="tw-inline-block">Closet Here</span>
					</ButtonPrimary>
				</div>
				<Wrapper className="tw-pt-70px md:tw-pt-80px">
					<div className="tw-leading-none tw-pb-10px md:tw-pb-0">
						<ScreenSubHeader tag="span">
							Featuring
						</ScreenSubHeader>
					</div>
					<div className="-tw-m-2px">
						<div className="tw-flex tw-flex-wrap tw-items-baseline tw-leading-none tw-justify-center ">
							<ScreenSubHeader className="tw-flex-shrink-0 tw-leading-none tw-p-2px">
								<span className="tw-text-[1.3em] md:tw-text-[1.786em] tw-leading-none">
									Costume Designer<span className="md:tw-hidden">.....</span>
								</span>
							</ScreenSubHeader>
							<span className="tw-p-2px tw-hidden md:tw-flex tw-border-b-10p dotted-border tw-flex-grow tw-relative -tw-top-10p tw-leading-none tw-mr-10px">&nbsp;</span>
							<ScreenHeader className="tw-flex-shrink-0 tw-leading-none tw-p-2px">
								<span className="tw-text-[1.067em] tw-normal-case tw-leading-none">
									Mona May
								</span>
							</ScreenHeader>
						</div>
					</div>
				</Wrapper>
				<Wrapper className="md:tw-py-40px">
					<WrapperInner>
						<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-20px tw-py-40px  md:tw-py-70px">
							<AspectImage src="/images/Mona_b_1-fs8.png" className="tw-w-full" aspect="541/668" />
							<AspectImage src="/images/Mona_b_2-fs8.png" className="tw-hidden md:tw-block tw-w-full" aspect="541/668" />
						</div>
						<ParagraphBlock className="tw-space-y-20px md:tw-space-y-30px tw-text-left tw-pb-60px">
							<p>
								Over the course of her celebrated career, Mona May has crafted wardrobes for some of the ‘90s and ‘00s most iconic films including <Span tag="i">
										Clueless,&nbsp;{` `}
									</Span>
									<Span tag="i">
										Romy &amp; Michelle's High School Reunion,&nbsp;{` `}
									</Span>
									<Span tag="i">
										Never Been Kissed
									</Span> and more.
							</p>
							<p>
								Together with Stitch Fix, May has leveraged her trademark style, featuring bold colors and strong shapes, as well as her masterful knowledge of costume design and storytelling to develop five curated closets.
							</p>
						</ParagraphBlock>
					</WrapperInner>
				</Wrapper>
			</div>
			<div className="tw-w-full tw-border-y-1px md:tw-border-y-3px tw-border-black">
				<ButtonPrimary className="tw-min-w-250px tw-px-20px tw-py-10px tw-w-full tw-min-h-75px" onClick={ handleOnClick }>
					<span>Discover your Main Character</span> <span className="tw-inline-block">Closet Here</span>
				</ButtonPrimary>
			</div>
			<footer className={`tw-bg-white tw-text-[#25282D] tw-py-40px md:tw-py-60px tw-text-center`}>
				<Wrapper>
					<WrapperInner>
						<ParagraphBlock>
							<p>
								Partner with one of Stitch Fix’s expert stylists to discover your “main character energy” <Span>and explore</Span> looks curated to <Span>match your style.</Span>
							</p>
						</ParagraphBlock>
					</WrapperInner>
				</Wrapper>
			</footer>
		</div>
	</>
}

export default HomeScreen
