const Wrapper = props => {
	const Tag = props.tag || 'div';
	const attributes = {
		...props,
		children: null,
		className: null
	}

	return <>
		<Tag { ...attributes } className={`tw-w-full tw-max-w-1560px ${ props.noPaddingMobile ? '' : 'tw-px-30px' } md:tw-px-60px tw-mx-auto ${ props.className ? " || " + props.className : '' }`}>
			{ props.children }
		</Tag>
	</>
}

export const WrapperInner = props => {
	const Tag = props.tag || 'div';
	const attributes = {
		...props,
		children: null,
		className: null
	}

	return <>
		<Tag { ...attributes } className={`tw-w-full tw-max-w-1100px tw-mx-auto ${ props.className ? " || " + props.className : '' }`}>
			{ props.children }
		</Tag>
	</>
}



export default Wrapper;
