export const Span = props => {
	const Tag = props.tag || 'span';

	const attributes = {
		...props,
		tag: null,
		className: null
	}

	return <>
		<Tag { ...attributes } className={`tw-inline-block ${ props.className ? ' || ' + props.className : '' }`}>

		</Tag>
	</>
}

export const Block = props => {
	const Tag = props.tag || 'span';

	const attributes = {
		...props,
		tag: null,
		className: null
	}

	return <>
		<Tag { ...attributes } className={`tw-block ${ props.className ? ' || ' + props.className : '' }`}>

		</Tag>
	</>
}
