const questions = [
	{
		title: `What's your favorite / era of TV?`,
		points: 1,
		options: [
			{
				title: `90s, I live for the throwbacks`,
				id: "A",
			},
			{
				title: `Contemporary, I love what's happening now`,
				id: "B",
			},
			{
				title: `00s, I'm Y2K all the way`,
				id: "C",
			},
			{
				title: `2010's, What I miss isn't so far away`,
				id: "D",
			},
			{
				title: `Call me timeless, I love it all.`,
				id: "E",
			},
		],
	},
	{
		title: `Where do you spend your / free time?`,
		points: 1,
		options: [
			{
				title: `Hanging with friends at a coffee shop`,
				id: "A",
			},
			{
				title: `Nestled up at a bookstore`,
				id: "C",
			},
			{
				title: `Lounging in your home office`,
				id: "B",
			},
			{
				title: `Shopping in the city streets`,
				id: "E",
			},
			{
				title: `Checking out the newest restaurants`,
				id: "D",
			},
		],
	},
	{
		title: `What's your / favorite pattern?`,
		points: 2,
		options: [
			{
				title: `Plaid`,
				id: "C",
			},
			{
				title: `Floral`,
				id: "E",
			},
			{
				title: `Animal Prints`,
				id: "D",
			},
			{
				title: `Polka Dots`,
				id: "A",
			},
			{
				title: `Simple Solid`,
				id: "B",
			},
		],
	},
	{
		title: `What's your <span class='tw-whitespace-nowrap'>must-have</span> / accessory?`,
		points: 2,
		options: [
			{
				title: `Colorful clutch`,
				id: "D",
			},
			{
				title: `Baseball cap`,
				id: "A",
			},
			{
				title: `Gold statement earrings`,
				id: "B",
			},
			{
				title: `Embellished headband`,
				id: "C",
			},
			{
				title: `Playful socks`,
				id: "E",
			},
		],
	},
	{
		title: `What's your go-to / shoe in the fall?`,
		points: 3,
		options: [
			{
				title: `Fun slide`,
				id: "E",
			},
			{
				title: `Retro sneakers`,
				id: "A",
			},
			{
				title: `High-heel pump`,
				id: "B",
			},
			{
				title: `Textured booties`,
				id: "D",
			},
			{
				title: `Chunky loafer`,
				id: "C",
			},
		],
	},
];

export default questions;
