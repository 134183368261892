const ButtonDefault = props => {
	const Tag = props.href ? 'a' : props.tag || 'button';
	const attributes = {
		...props,
		className: null,
		children: null,
		tag: null,
		childClasses: null
	}

	return <>
		<Tag { ...attributes } className={`${ props.className ? props.className + ' || '  : '' } tw-rounded-0px tw-shadow-none tw-outline-none || tw-inline-flex tw-justify-center tw-items-center || tw-outline-none tw-shadow-none tw-border-0 || tw-transition-all tw-duration-300 hover:tw-duration-500` }>
			<span className={`${ Tag === 'div' ? '' : 'tw-pointer-events-none ' } tw-leading-none ${ props.childClasses ? ' || ' + props.childClasses : '' }`}>
				{ props.children }
			</span>
		</Tag>
	</>
}

export const ButtonPrimary = props => {
	const attributes = {
		...props,
		className: null,
		children: null
	}

	return <>
		<ButtonDefault  { ...attributes } className={`${ props.className ? props.className + ' || '  : '' } tw-bg-[#98E2D8] ${ props.noHover ? '' : ' hover:tw-bg-black tw-text-black hover:tw-text-white ' }|| tw-font-bold`}>
			<span className="tw-font-primary tw-font-primary-medium tw-leading-wide tw-text-button tw-uppercase tw-leading-none">
				{ props.children }
			</span>
		</ButtonDefault>
	</>
}


export const ButtonSecondary = props => {
	const attributes = {
		...props,
		className: null
	}

	return <>
		<ButtonDefault { ...attributes } className={`${ props.className ? props.className + ' || '  : '' } tw-bg-yellow-600 hover:tw-bg-red-600 tw-text-white || tw-font-bold`}>
			{ props.children }
		</ButtonDefault>
	</>
}


export default ButtonDefault;
