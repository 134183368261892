import { useState, useEffect, useLayoutEffect } from "react";
import { useAppContext } from "../AppContext";
import { ButtonPrimary, ButtonSecondary } from "../components/atoms/Button";
import questions from "../utils/questions";
import AspectImage from "../components/atoms/aspect-image";
import { ParagraphBlock, SectionHeader } from "../components/atoms/headers";


export const removeSlash = (str) => str.replace(/\//g, '').replaceAll('  ', ' ');

export const titleFormat = (title) => {
	if (!title) return;

	if (title.includes(' /') ) {
		return title.split(' /');
	} else {
		return [title];
	}
}

export const titleFormatPromise = (title) => {
    return new Promise((resolve, reject) => {
        resolve(titleFormat(title));
    });
}

export const QuestionNumber = ({ number }) => {
	return <>
		<span className="tw-inline-flex tw-items-center tw-justify-center tw-aspect-square tw-rounded-full tw-border-3px tw-border-current tw-w-full tw-leading-none tw-select-none">
			<span className="tw-font-primary tw-font-primary-black tw-text-[1.5em]">
				{ Number(number) + 1 }
			</span>
		</span>
	</>
}


const QuestionTitle = ({ title, ...props}) => {
	const attributes = {
		...props,
		className: null,
		title: null
	}

	return <>
		<SectionHeader tag="h2" { ...attributes } className={`${ props.className ? ' || ' + props.className  : null }`}>
			<span className="tw-text-question tw-text-center tw-w-full tw-items-center tw-justify-center tw-flex-grow tw-flex tw-flex-col tw-divide-y-1px md:tw-divide-y-3px tw-divide-black tw-min-h-50px">
				<span className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-py-5px md:tw-py-10px tw-px-20px tw-w-full">
					<span className="tw-w-full tw-flex tw-flex-col tw-px-20px tw-space-y-3p tw-items-center tw-justify-center tw-flex-grow md:tw-text-[.7em] tw-leading-[.8]">
						{ title.map( (t, index) => {
							return <span key={ index } dangerouslySetInnerHTML={{__html: t}} />
						}) }
					</span>
				</span>
			</span>
		</SectionHeader>
	</>
}


const QuestionOption = ({ image, question, title, ...props}) => {
	const attributes = {
		...props,
		className: null,
		image: null,
		question: null,
		title: null
	}

	return <>
		<button { ...attributes } role="button" title={`${ title }`} className={`selection-option tw-flex-grow tw-cursor-pointer tw-outline-none tw-border-0px tw-shadow-none tw-rounded-0px tw-appearance-none || tw-bg-white hover:tw-bg-[#9AE4D3] active:tw-bg-[#6CB8A7] tw-group/option || tw-w-full tw-flex tw-flex-col tw-justify-stretch tw-divide-y-1px md:tw-divide-y-3px tw-divide-black || tw-transition-all tw-duration-300 hover:tw-duration-500  ${ props.className ? ' || ' + props.className  : '' }`}>
			<span className="tw-flex tw-py-20px md:tw-py-30px tw-px-20px md:tw-px-30px tw-w-full tw-flex-grow tw-flex-grow tw-pointer-events-none">
				<span className="tw-inline-flex tw-justify-center tw-items-center tw-w-9/12 md:tw-w-full md:tw-max-w-250px tw-mx-auto">
					{ title === 'Back' || title === 'Next' ? <> <AspectImage src={ '/images/control-left.svg' } alt={`${ question }: ${ title }`} imageClasses="tw-scale-100 tw-brightness-100 tw-opacity-100 group-hover/option:tw-brightness-0 group-hover/option:tw-opacity-70 || tw-transition-all tw-delay-150 hover:tw-delay-0 tw-duration-300 hover:tw-duration-500 " className={` tw-w-full ${ title === 'Next' ? 'tw-rotate-180' : ''}`} contain aspect="549/424"/> </> : <AspectImage src={ image || null } alt={`${ question }: ${ title }`} imageClasses="tw-object-center tw-scale-100 group-hover/option:tw-scale-110 || tw-transition-all tw-delay-300 hover:tw-delay-0 tw-duration-1000 hover:tw-duration-500 tw-absolute tw-w-full tw-h-full"  className="tw-w-full tw-relative tw-overflow-hidden tw-rounded-20px" aspect="345/225"/> }
				</span>
			</span>
			<ParagraphBlock tag="span" className="tw-min-h-50px sm:tw-min-h-70px tw-mt-auto tw-leading-none tw-flex tw-justify-center tw-items-center tw-px-20px tw-w-full tw-flex-shrink-0  tw-pointer-events-none">
				<span className={` tw-font-primary-medium ${ props.childClasses ? ' || ' + props.childClasses : ''}`}>{ title }</span>
			</ParagraphBlock>
		</button>
	</>
}


const QuestionScreen = ({ question, ...props }) => {
	const [title, setTitle] = useState("");
	const [titleObj, setTitleObj] = useState(null);
	const [selection, setSelection] = useState(null);
	const [isReady, setIsReady] = useState(false);
	const [counter, setCounter] = useState(0);
	const appContext = useAppContext();

	const attributes = {
		...props,
		children: null,
		className: null,
		index: null
	}


	const removeSiblingSelection = () => {
		return new Promise((resolve, reject) => {
			const options = document.querySelectorAll('.selection-option');
			options.forEach( option => {
				option.classList.remove('!tw-bg-[#9AE4D3]');
			})
			resolve();
		})
	}

	const removeObj = (num) => {
		if (!appContext.score) return;
		if (appContext.score && appContext.score.length === 0) return;
		return new Promise((resolve, reject) => {
			const temp = appContext.score.filter(obj => obj.question !== num);
			if (temp) {
				appContext.setScore(temp)
				resolve(temp);
			} else {
				reject(appContext.score)
			}
		});
	}

	useEffect(() => {
		if (selection) handleGoNext();
	}, [selection]);


	const handleOnClick = (e, id, questionPoints) => {
		const target = e.target;
		e.preventDefault();

		if (!target) return;

		removeSiblingSelection().then(() => {
			target.classList.add('!tw-bg-[#9AE4D3]');

			setSelection({
				question: props.index,
				value: id,
				points: questionPoints
			});


		});
	}

	useEffect(() => {
		if (appContext.currentQuestion < questions.length) {
			window.onbeforeunload = function() {
				return "Are you sure you want to leave?";
			}
		}
	}, []);

	const handleGoBack = (e) => {
		e.preventDefault();
		const current = Number(appContext.currentQuestion);

		setIsReady(false);
		setSelection(null);

		if (!current) {
			appContext.setCurrentQuestion( current === 0 ? null : current - 1 );
		} else {
			removeObj(current).then((res) => {
				appContext.setCurrentQuestion( current === 0 ? null : current - 1 );
			})
		}
	}

	const handleGoNext = (e) => {
		const current = Number(appContext.currentQuestion) || 0;

		if (e) e.preventDefault();

		setIsReady(false);
		setTimeout(() => {
			appContext.setScore([selection, ...appContext.score]);
			appContext.setCurrentQuestion( current + 1 );
		}, 500);


		if (questions.length <= props.index) {
			setIsReady(false);
			setSelection(null);
			appContext.setCurrentQuestion(null);
		}
	}

	useEffect(() => {
		if (appContext.currentQuestion === props.index) {
			// window.scrollTo(0, 0);
			// document.body.scrollTop = document.documentElement.scrollTop = 0;
			if (appContext.currentQuestion < questions.length) {
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			}

			setTimeout(() => {
				setIsReady(true);
			}, 50);
		}
		if (appContext.currentQuestion === 0) appContext.setScore([]);
	}, [appContext.currentQuestion])

	// useEffect(() => {
	// 	console.log(JSON.stringify(appContext.score, null, 4))
	// }, [appContext.score])

	useEffect(() => {
		setIsReady(false);
		setSelection(null);
		// window.scrollTo(0, 0);
		removeSiblingSelection();
	}, [appContext.currentQuestion]);

	useLayoutEffect(() => {
		setTitle( removeSlash( question.title ) );
		titleFormatPromise( question.title ).then( res => setTitleObj(res) );
	}, [question.title]);



	useEffect(() => {
		// if (question && title && appContext.currentQuestion === props.index) console.log(title && title.replace(/(<([^>]+)>)/gi, ""), appContext.currentQuestion, props.index);
		if (question && title && appContext.currentQuestion === props.index) appContext.ReactGA.send({ hitType: "pageview", page: `/#Question-${Number(appContext.currentQuestion) + 1}`, title: `Question #${Number(appContext.currentQuestion) + 1} - ${ title && title.replace(/(<([^>]+)>)/gi, "") }` });
	}, [question, appContext.currentQuestion, props.index, title, counter]);


	useLayoutEffect(() => {
		setIsReady(false);
		setSelection(null);
		window.scrollTo(0, 0);
	}, []);


	return question && titleObj ? <>
		<div { ...attributes }  className={`${ props.className ? props.className + ' || '  : '' } ${ appContext.currentQuestion === props.index ? 'tw-flex' : `tw-hidden`} tw-w-full tw-pt-header-height tw-min-h-vh tw-bg-[#9AE4D3]" tw-text-white tw-flex-col`}>
			<div className="tw-flex-grow tw-justify-start tw-flex tw-flex-col tw-flex-shrink-0 tw-max-w-full tw-bg-white tw-text-[#25282D]">
				<div title={ title } className={`tw-bg-white tw-sticky tw-top-header-height tw-z-2 tw-flex tw-w-full tw-items-center  tw-justify-center tw-flex-grow tw-border-b-1px md:tw-border-b-3px tw-border-black tw-py-10px tw-text-black md:tw-min-h-150px`}>
					<QuestionTitle  title={ titleObj } number={ props.index } className={`${ isReady === true ? 'tw-opacity-100 tw-scale-100' : 'tw-opacity-0 tw-scale-0' } tw-delay-0 tw-duration-500`} />
				</div>
				<div className="tw-bg-white tw-flex-shrink-0 tw-overflow-clip">
					<ul className={`${ isReady ? '' : '!tw-pointer-events-none !tw-select-none' } -tw-mx-1px md:-tw-mx-3px tw-flex tw-flex-wrap tw-justify-center tw-flex-row tw-divide-x-1px tw-divide-black md:tw-divide-x-0px`}>
						{ question.options.map( (option, index) => {
							return <li key={ index } className={`md:tw-order-[var(--order)] tw-flex-grow tw-w-1/2 tw-basis-1/2 md:tw-w-1/3 md:tw-basis-1/3 tw-flex tw-flex-col tw-justify-stretch tw-border-b-1px md:tw-border-b-3px md:!tw-border-r-3px tw-border-black ${ index === 0 ? ' tw-border-l-1px md:tw-border-l-3px' : ''}`} style={{ '--order': index + ( index === 3 || index === 4 ? 2 : 1) }}>
								{/* <QuestionOption className={`${isReady === true ? 'tw-opacity-100 tw-blur-none' : 'tw-opacity-0 tw-blur-lg' } tw-delay-300 tw-duration-500`} onClick={ e => handleOnClick(e, option.id, question.points || 0) } question={ option.title + `(${ option.id })` } title={ option.title + `(${ option.id })` } image={`/images/${ Number(props.index) +1 }_${ index + 1 }-fs8.png`} /> */}
								<QuestionOption className={`${isReady === true ? 'tw-opacity-100 tw-blur-none' : 'tw-opacity-0 tw-blur-lg' } tw-delay-300 tw-duration-500`} onClick={ e => handleOnClick(e, option.id, question.points || 0) } question={ title } title={ option.title } image={`/images/${ Number(props.index) +1 }_${ index + 1 }-fs8.png`} />
							</li>
						}) }
						<li className={`!tw-border-r-transparent md:!tw-border-x-black ! !tw-pointer-events-none tw-order-[var(--order)] tw-w-1/4 tw-basis-1/4 md:tw-w-2/12 md:tw-basis-2/12 tw-flex tw-flex-col tw-justify-stretch tw-border-b-1px md:tw-border-b-3px md:!tw-border-r-3px tw-border-black`} style={{ '--order': 3 }}>
							<QuestionOption  title="Back" className={`!tw-opacity-0`} onClick={ e => handleGoBack(e) } question={ appContext.currentQuestion } />
						</li>
						<li className={`!tw-border-x-transparent md:!tw-border-l-black !tw-pointer-events-none tw-order-[var(--order)] tw-w-1/4 tw-basis-1/4 md:tw-w-2/12 md:tw-basis-2/12 tw-flex tw-flex-col tw-justify-stretch tw-border-b-1px md:tw-border-b-3px tw-border-black`}  style={{ '--order': 10 }}>
							<QuestionOption className={`!tw-opacity-0 ${ selection ? '' : 'tw-grayscale tw-pointer-events-none tw-select-none' }`} childClasses={ selection ? '' : 'tw-opacity-50' } title="Next" onClick={ e => handleGoNext(e) } question={ appContext.currentQuestion } />
						</li>
					</ul>
				</div>
			</div>
			{/*
			<div className="tw-w-full tw-mt-auto tw-flex tw-justify-end">
				<ButtonSecondary className="tw-py-20px tw-px-40px">
					Next Question
				</ButtonSecondary>
			</div>
			*/}
		</div>
	</> : null
}

export default QuestionScreen
