import { useContext, createContext, useState, useMemo } from "react";
import ReactGA from "react-ga4";
ReactGA.initialize("G-JRB1SVPHLX");

const AppContext = createContext({
	score: 0,
	setScore: (() => undefined),
	currentQuestion: null,
	setCurrentQuestion: (() => undefined),
	ReactGA: ReactGA
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [score, setScore] = useState([]);

	// useEffect(() => {
	// 	console.log('currentQuestion (context)', currentQuestion)
	// }, [currentQuestion, score])

	const value = useMemo(() => ({
		currentQuestion, setCurrentQuestion,
		score, setScore,
		ReactGA
	}), [currentQuestion, score, ReactGA]);

	return (
		<AppContext.Provider value={ value }>
			{ children }
		</AppContext.Provider>
	);
}
